/* eslint-env browser */
import React from "react";
import {useForm, useFormState} from "react-hook-form";
import PropTypes from "prop-types";

import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import FilledInput from "@mui/material/FilledInput";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import Popper from "@mui/material/Popper";
import {alpha} from "@mui/material/styles";
import useTheme from "@mui/material/styles/useTheme";

import {splitFilterInput} from "@tzmedical/react-hooks/useFilter";

import SearchHelperRow from "./SearchHelperRow.jsx";

//---------------------------------------------------------------------------
function SearchBar({
  // Props
  search,
  searchHelper,
  setSearch,
  autoFillValues,
}) {
  const theme = useTheme();
  const anchorEl = React.useRef();
  const inputRef = React.useRef();

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const defaultValues = React.useMemo(() => {
    const defaults = {};
    if (Array.isArray(searchHelper)) {
      searchHelper.forEach((helper) => {
        if (helper.variant === "relative" || helper.variant === "inequality") {
          defaults[`${helper.keyword}-${helper.variant}`] = ">";
        }
        if (helper.variant === "negatable") {
          defaults[`${helper.keyword}-negatable`] = "+";
        }
        if (helper.variant === "toggle") {
          defaults[`${helper.keyword}-negatable`] = "";
        }
        defaults[helper.keyword] = "";
      });
    }
    return defaults;
  }, [searchHelper]);
  const {handleSubmit, control, reset} = useForm({defaultValues});
  const {dirtyFields} = useFormState({control});

  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleSearch = React.useCallback(
    (event) => {
      setSearch(event.target.value);
      window.scrollTo(0, 0);
    },
    [setSearch]
  );
  const clearSearch = React.useCallback(() => {
    setSearch("");
    reset(defaultValues);
    window.scrollTo(0, 0);
  }, [defaultValues, reset, setSearch]);

  const onSubmit = React.useCallback(
    async (data) => {
      // Construct the search string based on the searchHelper config
      const newSearch = searchHelper?.reduce((string, row) => {
        if (row.variant === "toggle") {
          if (dirtyFields[`${row.keyword}-negatable`]) {
            const isNegated = data[`${row.keyword}-negatable`] === "-";
            string += isNegated ? `-${row.keyword} ` : `${row.keyword} `;
          }
        } else if (dirtyFields[row.keyword]) {
          const wordsOrPhrases = splitFilterInput(data[row.keyword]);
          // wordsOrPhrases returns an array like [{fields: [], value: "input"}]
          string += wordsOrPhrases.reduce((substring, phrase) => {
            const value = phrase.value.includes(" ") ? `"${phrase.value}"` : phrase.value;
            if (row.variant === "global") {
              if (row.keyword === "-") {
                substring += `-${value} `;
              } else {
                substring += `${value} `;
              }
            } else if (row.variant === "negatable") {
              const isNegated = data[`${row.keyword}-negatable`] === "-";
              if (isNegated) {
                substring += `-${row.keyword}:${value} `;
              } else {
                substring += `${row.keyword}:${value} `;
              }
            } else if (row.variant === "relative" || row.variant === "inequality") {
              const relativeString = data[`${row.keyword}-${row.variant}`];
              if (relativeString === "=") {
                substring += `${row.keyword}:${value} `;
              } else {
                substring += `${row.keyword}:${relativeString}${value} `;
              }
            } else if (row.variant === "toggle") {
              const isNegated = data[`${row.keyword}-negatable`] === "-";
              if (isNegated) {
                substring += `-${row.keyword} `;
              } else {
                substring += `${row.keyword} `;
              }
            } else {
              substring += `${value} `;
            }
            return substring;
          }, "");
        }
        return string;
      }, "");

      setSearch(newSearch);
      setOpen(false);
      window.scrollTo(0, 0);
    },
    [dirtyFields, searchHelper, setSearch]
  );

  React.useEffect(() => {
    // Clear form on rerender
    return () => reset(defaultValues);
  }, [defaultValues, reset]);

  //---------------------------------------------------------------------------
  // Autocomplete support
  //---------------------------------------------------------------------------
  const autoCompleteSearch = React.useMemo(() => {
    const index = search.lastIndexOf(" ");
    if (index > 0) {
      return search.substring(index + 1, search.length);
    }
    return search;
  }, [search]);

  const autoCompleteMatches = React.useMemo(() => {
    if (!autoCompleteSearch.length) {
      return [];
    }
    if (autoFillValues.some((val) => val === autoCompleteSearch)) {
      return [];
    }
    return autoFillValues.filter((val) => val.startsWith(autoCompleteSearch));
  }, [autoCompleteSearch, autoFillValues]);

  const handleAutoComplete = React.useCallback(
    (event) => {
      const index = search?.lastIndexOf(" ");
      if (index > 0) {
        setSearch(`${search.substring(0, index)} ${event.target.id}`);
      } else {
        setSearch(event.target.id);
      }
      inputRef.current.focus();
    },
    [search, setSearch]
  );

  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <FilledInput
          color="primary"
          fullWidth
          ref={anchorEl}
          inputRef={inputRef}
          sx={{
            color: theme.palette.common.white,
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            margin: "5px",
            borderRadius: "10px",
            "&:hover": {
              backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            "&.Mui-focused": {
              backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
          }}
          disableUnderline
          inputProps={{id: "searchBarInput", sx: {py: "10px"}}}
          data-cy="search"
          startAdornment={
            <InputAdornment sx={{color: theme.palette.common.white}} position="start">
              <SearchIcon />
            </InputAdornment>
          }
          placeholder="Search here..."
          value={search}
          onChange={handleSearch}
          endAdornment={
            <InputAdornment sx={{color: theme.palette.common.white}} position="end">
              <ButtonGroup variant="outlined">
                {search && (
                  <IconButton
                    aria-label="clear-search"
                    data-cy="clear-search"
                    // id for Protractor...
                    id="clearSearchBar"
                    color="inherit"
                    onClick={clearSearch}
                  >
                    <CancelIcon />
                  </IconButton>
                )}
                {searchHelper && (
                  <IconButton
                    aria-label="clear-search"
                    data-cy="clear-search"
                    color="inherit"
                    onClick={handleOpen}
                  >
                    <TuneIcon />
                  </IconButton>
                )}
              </ButtonGroup>
              {searchHelper && (
                <Popover
                  id="search-helper"
                  anchorEl={anchorEl.current}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Box sx={{width: anchorEl?.current?.offsetWidth, m: 3}}>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                      {searchHelper.map((helper) => (
                        <SearchHelperRow
                          key={helper.keyword}
                          label={helper.label}
                          variant={helper.variant}
                          control={control}
                          keyword={helper.keyword}
                          defaultValues={defaultValues}
                          options={helper.options}
                        />
                      ))}
                      <Box textAlign="right" marginTop={3}>
                        <Button color="secondary" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button
                          color="secondary"
                          data-cy="submit-search"
                          variant="contained"
                          type="submit"
                          sx={{ml: 2}}
                        >
                          Search
                        </Button>
                      </Box>
                    </form>
                  </Box>
                </Popover>
              )}
            </InputAdornment>
          }
        />
        {autoFillValues?.length > 0 && (
          <Popper
            anchorEl={inputRef.current}
            open={autoCompleteMatches.length > 0}
            sx={{zIndex: 10, boxShadow: 2}}
          >
            <Paper p={0.5}>
              {autoCompleteMatches.map((value) => (
                <MenuItem key={value} id={value} onClick={handleAutoComplete}>
                  {value}
                </MenuItem>
              ))}
            </Paper>
          </Popper>
        )}
      </Grid>
    </Grid>
  );
}

SearchBar.propTypes = {
  search: PropTypes.string.isRequired,
  searchHelper: PropTypes.array,
  setSearch: PropTypes.func.isRequired,
  autoFillValues: PropTypes.array,
};

export default React.memo(SearchBar);
