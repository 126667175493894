/* eslint-env browser */
import React from "react";
import {useForm, useFormState} from "react-hook-form";
import axios from "axios";
import PropTypes from "prop-types";
import isEmail from "validator/es/lib/isEmail";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PersonIcon from "@mui/icons-material/Person";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import Alert from "../../shared/react/Alert.jsx";
import CancelButton from "../../shared/react/CancelButton.jsx";
import FormStringInput from "../../shared/react/FormStringInput.jsx";
import useEnvironmentVariables from "../hooks/useEnvironmentVariables.jsx";
import useValidatePasswordStrength from "../hooks/useValidatePasswordStrength.jsx";

const usernameMessage = (
  <Typography>
    <b>Forgot your username?</b> Contact your admin for assistance.
  </Typography>
);
const passwordMessage = (
  <Typography>
    <b>Forgot your password?</b> Enter your username and email below.
  </Typography>
);
const newPasswordMessage = (
  <Typography>Please check your email and enter the code you received below.</Typography>
);

function ResetAccount({
  // Props
  username,
}) {
  const {apiUrl} = useEnvironmentVariables();

  //---------------------------------------------------------------------------
  // State Management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [userId, setUserId] = React.useState(null);
  const [secret, setSecret] = React.useState(null);
  const [emailSent, setEmailSent] = React.useState(false);
  const [score, setScore] = React.useState(0);
  const [suggestionList, setSuggestionList] = React.useState([]);
  const [message, setMessage] = React.useState(null);

  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = React.useCallback(() => {
    setOpen(false);
    setError(null);
    setLoading(false);
    setUserId(null);
    setSecret(null);
    setEmailSent(false);
    setScore(0);
    setSuggestionList([]);
  }, []);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const {handleSubmit, control} = useForm();
  const {isDirty} = useFormState({control});

  //---------------------------------------------------------------------------
  // Submit Handler
  //---------------------------------------------------------------------------
  const onSubmit = React.useCallback(
    async (formData) => {
      // Disable the submit button and display the spinner
      setLoading(true);
      setError(null);

      if (!emailSent) {
        // STEP 1: Verify user exists
        try {
          const response = await axios({
            method: "get",
            url: `${apiUrl}/users/generateResetString`,
            params: {username: formData.username, email: formData.email},
          });

          setUserId(response.data.userId);
          setSecret(response.data.resetPasswordString);

          // STEP 2: Send email with reset code
          await axios({
            method: "post",
            url: `${apiUrl}/users/${response.data.userId}/sendResetCode`,
            params: {username: formData.username, email: formData.email},
            data: {
              resetPasswordString: response.data.resetPasswordString,
            },
          });

          setEmailSent(true);
        } catch (err) {
          setError(err.response?.data?.error || err.response?.data?.message || "Invalid username or email");
        }
      } else {
        try {
          // STEP 3: Enter emailed code and set new password
          await axios({
            method: "patch",
            url: `${apiUrl}/users/${userId}/resetPassword`,
            params: {username: formData.username, email: formData.email},
            data: {
              password: formData.password,
              resetPasswordString: secret,
              code: formData.code,
            },
          });

          handleClose();
          setMessage("Your password has been changed.");
        } catch (err) {
          setError(err.response?.data?.error || err.response?.data?.message || "Invalid code or email");
        }
      }
      setLoading(false);
    },
    [apiUrl, emailSent, handleClose, setMessage, secret, userId]
  );

  const validatePasswordStrength = useValidatePasswordStrength(setScore, setSuggestionList);

  const scoreColor = React.useMemo(() => {
    if (score < 40) {
      return "error";
    }
    if (score < 60) {
      return "warning";
    }
    return "success";
  }, [score]);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <Box sx={{p: 2}} textAlign="center">
      <Alert message={message} setMessage={setMessage} level="info" variant="snackbar" />
      <Button variant="text" data-cy="forgot-username-or-password" onClick={handleOpen}>
        Forgot Username or Password?
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth data-cy="reset-account-form">
        <Alert message={error} setMessage={setError} level="error" />
        <DialogTitle style={{display: "flex", alignItems: "center", flexWrap: "wrap"}}>
          <PersonIcon color="secondary" />
          &nbsp;&nbsp; {!emailSent && "Request Password Reset"}
          {emailSent && "Select New Password"}
        </DialogTitle>
        <Divider sx={{bgcolor: "secondary.main"}} />
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogContent>
            {!emailSent && (
              <>
                <Alert message={usernameMessage} level="info" />
                <br />
                <Alert message={passwordMessage} level="success" otherProps={{sx: {paddingTop: "1px"}}} />
              </>
            )}
            {emailSent && <Alert message={newPasswordMessage} level="success" />}
            <br />
            <Grid container spacing={5} justifyContent="center">
              {/* Username */}
              {!emailSent && (
                <Grid item xs={8}>
                  <FormStringInput
                    control={control}
                    defaultValue={username || ""}
                    label="Username"
                    name="username"
                    type="username"
                    data-cy="reset-account-username-input"
                    rules={{
                      required: "Please enter your username to reset your account.",
                    }}
                  />
                </Grid>
              )}
              {/* Email */}
              {!emailSent && (
                <Grid item xs={8}>
                  <FormStringInput
                    control={control}
                    defaultValue=""
                    label="Email"
                    name="email"
                    data-cy="reset-account-email-input"
                    rules={{
                      required: "Please enter your email to reset your account.",
                      validate: {
                        isValidEmail: (email) =>
                          isEmail(email) ? true : "Please enter a valid email address",
                      },
                    }}
                  />
                </Grid>
              )}
              {emailSent && (
                <>
                  <Grid item xs={8}>
                    <FormStringInput
                      control={control}
                      defaultValue=""
                      label="Security Code"
                      name="code"
                      type="text"
                      data-cy="security-code-input"
                      rules={{
                        required: "Please enter the security code from your email.",
                      }}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <FormStringInput
                      control={control}
                      defaultValue=""
                      label="New Password"
                      name="password"
                      type="password"
                      data-cy="new-password-input"
                      rules={{
                        required: "Please select a new password.",
                        minLength: {
                          value: 20,
                          message: "Your password must be at least 20 characters long.",
                        },
                        validate: {
                          strength: validatePasswordStrength,
                        },
                      }}
                    />
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs="auto">
                        <Typography variant="caption">Strength:</Typography>
                      </Grid>
                      <Grid item xs>
                        <LinearProgress
                          variant="determinate"
                          value={score}
                          color={scoreColor}
                          sx={{borderRadius: "5px"}}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {suggestionList?.length ? (
                    <Grid item xs={7}>
                      <Typography variant="h6" marginBottom="8px">
                        Suggestions:
                      </Typography>
                      {suggestionList?.map((suggestion) => (
                        <Grid container spacing={1} alignItems="center" key={suggestion}>
                          <Grid item xs="auto">
                            <InfoOutlinedIcon color="secondary" fontSize="small" />
                          </Grid>
                          <Grid item xs>
                            <Typography variant="caption">{suggestion}</Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  ) : null}
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box m={2}>
              <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
                Cancel
              </CancelButton>
            </Box>
            <Box m={2}>
              <LoadingButton
                data-cy="reset-submit-button"
                disabled={loading}
                variant="contained"
                color="secondary"
                loading={loading}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

ResetAccount.propTypes = {
  username: PropTypes.string,
};

export default ResetAccount;
