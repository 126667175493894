/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useFilter, useInterval, useLocalStorage, useSort} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import {useStudies, useStudiesDispatch} from "../../contexts/StudiesContext.jsx";
import Alert from "../../shared/react/Alert.jsx";
import FloatingActionBuffer from "../../shared/react/FloatingActionBuffer.jsx";
import NoResults from "../../shared/react/NoResults.jsx";
import Pagination from "../../shared/react/Pagination.jsx";
import TableLoading from "../../shared/react/TableLoading.jsx";
import {doesNotMeetExtendedHolterRequirements} from "../hooks/useDoesNotMeetExtendedHolterRequirements.jsx";
import useEnvironmentVariables from "../hooks/useEnvironmentVariables.jsx";
import useJwt from "../hooks/useJwt.jsx";
import useStudyTypeNames from "../hooks/useStudyTypeNames.jsx";

//---------------------------------------------------------------------------
// Lazy-load page-specific components
//---------------------------------------------------------------------------
const StudiesHeader = React.lazy(() => import(/* webpackPrefetch: true */ "./StudiesHeader.jsx"));
const StudiesRow = React.lazy(() => import(/* webpackPrefetch: true */ "./StudiesRow.jsx"));
const NewStudyDialog = React.lazy(
  () => import(/* webpackPrefetch: true */ "../../dialogs/StudyDialogs/NewStudy/NewStudyDialog.jsx")
);

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

//---------------------------------------------------------------------------
// Search and Sort options
//---------------------------------------------------------------------------
const constantSearchFields = {
  patient: [(object) => object.studyDetails?.patientName, (object) => object.studyDetails?.patientId],
  device: (object) => object.currentEnrollment?.tzSerial,
  id: "id",
  study: "id",
  order: "orderNumber",
  indication: "studyIndication",
  is: {
    unconfigured: (object) => !!object.currentEnrollment?.pendingSettingsDownload,
    pending: (object) => !object.dataReceived,
    recording: (object) => object.dataReceived && object.studyState === "active" && !object.studyEndDate,
    done: (object) => object.dataReceived && object.studyState === "active" && !!object.studyEndDate,
    finalized: (object) => object.studyState === "finalized",
    archived: (object) => object.studyState === "archived",
    failed: (object) => object.studyState === "failed",
  },
  start: "studyStartDate",
  end: "studyEndDate",
  finalized: "finalizedAt",
  facility: ["facilityId", (object) => object.facility?.name],
  recording: (object) => object.currentEnrollment?.deviceEnrollmentId,
  dob: (object) => object.studyDetails?.patientDob,
  language: (object) => object.studyDetails?.patientLanguage,
  gender: (object) => object.studyDetails?.patientGender,
  height: (object) => object.studyDetails?.patientHeight,
  weight: (object) => object.studyDetails?.patientWeight,
  phone: (object) => object.studyDetails?.patientPhone,
  emergencyContact: (object) => object.studyDetails?.emergencyContactName,
  emergencyPhone: (object) => object.studyDetails?.emergencyContactPhone,
  address: (object) => object.studyDetails?.patientAddress,
  "physician-name": (object) => object.studyDetails?.physicianName,
  "physician-phone": (object) => object.studyDetails?.physicianPhone,
  "physician-facility": (object) => object.studyDetails?.physicianFacility,
  "physician-email": (object) => object.studyDetails?.physicianEmail,
  "physician-address": (object) => object.studyDetails?.physicianAddress,
  physician: [
    (object) => object.studyDetails?.physicianName,
    (object) => object.studyDetails?.physicianPhone,
    (object) => object.studyDetails?.physicianFacility,
    (object) => object.studyDetails?.physicianEmail,
    (object) => object.studyDetails?.physicianAddress,
  ],
  // This is a little messy, but so is the underlying intent - to be able to search any arbitrary number of array elements on any property.
  insurance: [
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.type?.name)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.insuranceCompany)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.relationToPatient)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.subscriberIdNumber)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.subscriberName)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.subscriberDob)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.subscriberSex)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.subscriberAddress)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.policyNumber)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.groupNumber)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.claimsPhoneNumber)),
    (object) => JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.claimsAddress)),
    (object) =>
      JSON.stringify(object.insurance?.map((insurancePlan) => insurancePlan.priorAuthorizationNumber)),
  ],
};
// NOTE: delete this function update when removing feature toggle for finalizeStudy (FEATURE_FINALIZE_STUDY)
constantSearchFields.is.finalized = (object) => {
  return ["completed", "finalized"].includes(object.studyState);
};
const sortOptions = {
  defaultSort: {
    field: "studyStartDate",
    reverse: true,
  },
  fieldGetters: {
    patientName: (study) => study.studyDetails?.patientName,
    status: (study) => {
      if (study.studyState === "finalized") {
        return "Finalized";
      }
      // NOTE: delete this block when removing feature toggle for finalizeStudy (FEATURE_FINALIZE_STUDY)
      if (study.studyState === "completed") {
        return window.globalConfig.features.finalizeStudy ? "Finalized" : "Completed";
      }
      if (study.studyState === "archived") {
        return "Archived";
      }
      if (study.studyState === "failed") {
        return "Failed";
      }
      if (!study.dataReceived && study.studyState === "active") {
        return "Pending";
      }
      if (!study.studyEndDate && study.studyState === "active") {
        return "Recording";
      }
      if (study.studyEndDate && study.studyState === "active") {
        return "Done Recording";
      }
      return "Failed";
    },
  },
};

function StudiesTable({
  // Props
  searchText = "",
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const displayableStudyTypes = useStudyTypeNames();
  const {features} = useEnvironmentVariables();

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const [tableLoading, setTableLoading] = React.useState(true);
  const studies = useStudies();
  const dispatch = useStudiesDispatch();

  const getStudies = React.useCallback(async () => {
    try {
      const [{data: studiesResponse}, {data: actionsResponse}] = await Promise.all([
        axios({
          method: "get",
          url: "/studies",
        }),
        // For each format device action, set checkInDeviceInProgress to true on the study
        axios({
          method: "get",
          url: "/actions",
          params: {
            name: "formatDevice",
            status: {$or: ["pending", "sent"]},
            attributes: ["enrollmentId", "name", "deviceId"],
          },
        }),
      ]);

      actionsResponse.forEach((action) => {
        const correspondingStudy = studiesResponse.find((study) => {
          return (
            study.enrollmentId === action.enrollmentId &&
            study.currentEnrollment?.deviceId === action.deviceId
          );
        });
        if (correspondingStudy) {
          correspondingStudy.checkInDeviceInProgress = true;
        }
      });

      dispatch({type: "init", elements: studiesResponse});
      setError(null);
    } catch (err) {
      // Only display 500 error popup if we failed to load studies for the page
      if (err.response?.status !== 500 || studies.length === 0) {
        setError(err?.message);
      } else {
        setError(null);
      }
    }
    setTableLoading(false);
  }, [dispatch, studies.length]);

  useInterval(getStudies, DATA_REFRESH_INTERVAL_MS, tableLoading);

  //---------------------------------------------------------------------------
  // Search support
  //---------------------------------------------------------------------------
  const [search, setSearch] = React.useState("");
  const {username} = useJwt();
  const [statusFilter, setStatusFilter] = useLocalStorage(`${username}.studies.filter`, {
    pending: true,
    recording: true,
    done: true,
    finalized: true,
    archived: false,
    failed: false,
  });

  React.useEffect(() => {
    const statusToFilterOut = Object.entries(statusFilter)
      .filter(([status, selected]) => !selected)
      .map(([status, selected]) => `-is:${status}`);

    setSearch(`${searchText} ${statusToFilterOut.join(" ")}`);
  }, [statusFilter, searchText]);

  const searchFields = React.useMemo(() => {
    return {
      ...constantSearchFields,
      ...(features.evaluationContract && {
        contract: {
          none: (object) => object.inboxContract === "none",
          device: (object) => object.inboxContract === "feePerDevice",
          service: (object) => object.inboxContract === "feePerService",
          split: (object) => object.inboxContract === "splitBilling",
          evaluation: (object) => object.inboxContract === "evaluation",
        },
      }),
      ...(features.downgradeAuthorized && {
        is: {
          ...constantSearchFields.is,
          downgradeAuthorized: (object) => object.downgradeAuthorized,
          meetingReportCriteria: (object) => {
            return !doesNotMeetExtendedHolterRequirements(
              object?.studyType,
              object?.downgradeAuthorized,
              object?.configuredDuration,
              object?.recordedDuration
            );
          },
        },
      }),
      "study-type": [(object) => object.studyType, (object) => displayableStudyTypes[object.studyType]],
    };
  }, [features.evaluationContract, features.downgradeAuthorized, displayableStudyTypes]);
  const filteredStudies = useFilter(studies, search, searchFields);

  //---------------------------------------------------------------------------
  // Sorting support
  //---------------------------------------------------------------------------
  const [sortedStudies, handleSortSelection, sort] = useSort(filteredStudies, sortOptions);

  //---------------------------------------------------------------------------
  // Pagination support
  //---------------------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const pageSize = 50;
  const pageStudies = React.useMemo(
    () => sortedStudies.slice(page * pageSize, (page + 1) * pageSize),
    [page, sortedStudies]
  );

  return (
    <>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />

      {
        //---------------------------------------------------------------------------
        // Display a loading spinner if we're still waiting on the API
        //---------------------------------------------------------------------------
        tableLoading && <TableLoading />
      }
      {!tableLoading && (
        <>
          <StudiesHeader
            sort={sort}
            setSort={handleSortSelection}
            sortedStudies={sortedStudies}
            setError={setError}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
          />

          {
            //---------------------------------------------------------------------------
            // Display a message if there are no matching results, instead of the table
            //---------------------------------------------------------------------------
            sortedStudies.length === 0 && <NoResults />
          }

          {
            //---------------------------------------------------------------------------
            // Render the table and pagination
            //---------------------------------------------------------------------------
            sortedStudies.length > 0 && (
              <>
                {pageStudies.map((study) => (
                  <StudiesRow key={study.id} study={study} alwaysOpen={pageStudies.length === 1} />
                ))}
                <Pagination pageSize={pageSize} page={page} setPage={setPage} count={sortedStudies.length} />
              </>
            )
          }

          <NewStudyDialog />
        </>
      )}
      <FloatingActionBuffer />
    </>
  );
}
StudiesTable.propTypes = {
  searchText: PropTypes.string,
};
export default StudiesTable;
