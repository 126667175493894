/* eslint-env browser */
import React from "react";
import {useForm} from "react-hook-form";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import AddCommentOutlined from "@mui/icons-material/AddCommentOutlined";
import Delete from "@mui/icons-material/Delete";
import ModeCommentOutlined from "@mui/icons-material/ModeCommentOutlined";
import Save from "@mui/icons-material/Save";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";
import FormStringInput from "../../shared/react/FormStringInput.jsx";
import IconButtonWithTooltip from "../../shared/react/IconButtonWithTooltip.jsx";
import ShowMoreItems from "../../shared/react/ShowMoreItems.jsx";
import DateAndTime from "../DateAndTime/DateAndTime.jsx";
import useJwt from "../hooks/useJwt.jsx";

function StudyNotes({
  // Props
  study,
  setError,
}) {
  //---------------------------------------------------------------------------
  // Manage saving and clearing study notes
  //---------------------------------------------------------------------------
  const [submitting, setSubmitting] = React.useState(false);
  const {handleSubmit, control, watch, reset} = useForm();
  const watchStudyNoteInput = watch("studyNote") || "";

  const {fullName} = useJwt();

  const onSubmit = React.useCallback(
    async (data) => {
      // Disable the input field and save/clear buttons
      setSubmitting(true);

      const newStudyNote = {
        fullName,
        timestamp: DateTime.now().valueOf(),
        note: data.studyNote,
      };

      try {
        await axios({
          method: "post",
          url: `/studies/studyNotes/${study.id}`,
          data: newStudyNote,
        });

        study.studyNotes.push(newStudyNote);

        // Clear the input field
        reset();
      } catch (err) {
        setError(err.message);
      }

      setSubmitting(false);
    },
    [fullName, study.id, study.studyNotes, reset, setError]
  );
  const handleCancel = React.useCallback(() => {
    // Just clear the input field
    reset();
  }, [reset]);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      <ListItem
        disablePadding
        secondaryAction={
          watchStudyNoteInput.length > 0 && (
            <div>
              <IconButtonWithTooltip
                title="Save Study Note"
                color="secondary"
                onClick={handleSubmit(onSubmit)}
                otherProps={{size: "small", disabled: submitting}}
                data-cy="save-study-note-button"
              >
                <Save fontSize="inherit" />
              </IconButtonWithTooltip>

              <IconButtonWithTooltip
                title="Clear Study Note"
                color="secondary"
                onClick={handleCancel}
                otherProps={{size: "small", disabled: submitting}}
                data-cy="clear-study-note-button"
              >
                <Delete fontSize="inherit" />
              </IconButtonWithTooltip>
            </div>
          )
        }
      >
        <ListItemIcon sx={{minWidth: 30}}>
          <AddCommentOutlined fontSize="small" color="secondary" />
        </ListItemIcon>
        <ListItemText>
          <FormStringInput
            name="studyNote"
            control={control}
            defaultValue=""
            required={false}
            disabled={submitting}
            fullWidth={false}
            size="small"
            otherProps={{
              placeholder: "Enter new study note...",
              sx: {width: "85%"},
              InputProps: {sx: {fontSize: 14, color: "tertiary"}},
            }}
            data-cy="study-note-input"
          />
        </ListItemText>
      </ListItem>

      <ShowMoreItems limit={4} data-cy="study-notes-show-more-button">
        {study.studyNotes.reduceRight(
          (accumulator, studyNote) => [
            ...accumulator,
            <ListItem key={studyNote.timestamp} disableGutters alignItems="flex-start">
              <ListItemIcon sx={{minWidth: 30, m: 0}}>
                <ModeCommentOutlined fontSize="small" color="tertiary" />
              </ListItemIcon>
              <ListItemText sx={{color: "tertiary", m: 0}}>
                <Grid container columns={14} spacing={1}>
                  <Grid item xs={4} data-cy={`study-note-${studyNote.timestamp}-timestamp`}>
                    <DateAndTime datetime={studyNote.timestamp} zone={study.timeZone} />
                  </Grid>
                  <Grid item xs={3} zeroMinWidth data-cy={`study-note-${studyNote.timestamp}-user`}>
                    {studyNote.fullName}
                  </Grid>
                  <Grid item xs={7} zeroMinWidth data-cy={`study-note-${studyNote.timestamp}-note`}>
                    {studyNote.note}
                  </Grid>
                </Grid>
              </ListItemText>
            </ListItem>,
          ],
          []
        )}
      </ShowMoreItems>
    </>
  );
}

StudyNotes.propTypes = {
  study: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
};

export default StudyNotes;
