/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function ResourcesSection({
  // Props
  resource,
}) {
  //---------------------------------------------------------------------------
  // Render the grid of resources
  //---------------------------------------------------------------------------
  return (
    <Grid item key={resource.id} xs={6}>
      <img className="support-icon" src={resource.img} alt={resource.name} />
      <br />
      <Typography variant="button">
        <Button
          color="secondary"
          variant="text"
          data-cy={`inbox-${resource.id}-link`}
          target="_blank"
          href={resource.link}
        >
          {resource.name}
        </Button>
      </Typography>
      <Typography variant="body2">{resource.description}</Typography>
    </Grid>
  );
}

ResourcesSection.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default ResourcesSection;
