import React from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import FormStringInput from "../../shared/react/FormStringInput.jsx";

const relativeOptions = [
  {id: ">", name: "Greater than"},
  {id: ">=", name: "Greater than or equal to"},
  {id: "=", name: "Equal to"},
  {id: "<=", name: "Less than or equal to"},
  {id: "<", name: "Less than"},
];

const inequalityOptions = [
  {id: ">", name: "Greater than"},
  {id: "<", name: "Less than"},
];

const negatableOptions = [
  {id: "select", name: "-- Select Option --", value: ""},
  {id: "+", name: "Include"},
  {id: "-", name: "Exclude"},
];
function SearchHelperRow({
  // Props
  label,
  variant,
  control,
  defaultValues,
  keyword,
  options,
}) {
  return (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid item md={3} sx={{display: {xs: "none", md: "block"}}}>
        <Typography>{label}</Typography>
      </Grid>
      {variant === "relative" && (
        <Grid item xs={6} sm={4} md={3}>
          <FormStringInput
            control={control}
            defaultValue={defaultValues[`${keyword}-relative`]}
            required={false}
            name={`${keyword}-relative`}
            options={relativeOptions}
            data-cy={`${keyword}-relative-search-input`}
          />
        </Grid>
      )}
      {variant === "inequality" && (
        <Grid item xs={6} sm={4} md={3}>
          <FormStringInput
            control={control}
            defaultValue={defaultValues[`${keyword}-inequality`]}
            required={false}
            name={`${keyword}-inequality`}
            options={inequalityOptions}
            data-cy={`${keyword}-inequality-search-input`}
          />
        </Grid>
      )}
      {(variant === "negatable" || variant === "toggle") && (
        <Grid item xs={6} sm={4} md={3} mt={2}>
          <FormStringInput
            control={control}
            defaultValue={defaultValues[`${keyword}-negatable`]}
            required={false}
            name={`${keyword}-negatable`}
            options={negatableOptions}
            data-cy={`${keyword}-negatable-search-input`}
          />
        </Grid>
      )}
      {variant !== "toggle" && (
        <Grid item xs>
          <FormStringInput
            control={control}
            defaultValue={defaultValues[keyword]}
            required={false}
            label={label}
            name={keyword}
            options={options}
            data-cy={`${keyword}-search-input`}
          />
        </Grid>
      )}
    </Grid>
  );
}

SearchHelperRow.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  defaultValues: PropTypes.object.isRequired,
  keyword: PropTypes.string.isRequired,
  options: PropTypes.array,
};

export default SearchHelperRow;
