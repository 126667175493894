/* eslint-env browser */
import React from "react";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import ContactSupport from "@mui/icons-material/ContactSupport";
import Phone from "@mui/icons-material/Phone";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import ButtonWithLink from "../../shared/react/ButtonWithLink.jsx";
import useEnvironmentVariables from "../hooks/useEnvironmentVariables.jsx";

function ContactCard() {
  const supportMessage =
    "Please contact support if you encounter any problems and need further assistance. " +
    "The support team will respond to your inquiries within one business day.";
  const {supportPortalUrl} = useEnvironmentVariables();
  const supportPhoneNumber = "+18009440187";
  const userFriendlyPhoneNumber = "(800) 944-0187";

  return (
    <Card sx={{width: "100%", my: 1}}>
      <CardContent>
        <Typography variant="h5" align="center">
          Contact Our Team
        </Typography>
        <Typography variant="body2" align="center" sx={{padding: "14px"}}>
          {supportMessage}
        </Typography>
        <Grid container spacing={3} justifyContent="space-evenly" alignItems="center">
          <Grid item xs={6} align="right">
            <Typography variant="button" sx={{padding: "14px"}}>
              <ButtonWithLink
                color="secondary"
                variant="text"
                data-cy="customer-support-button"
                link={supportPortalUrl}
                target="_blank"
              >
                <ContactSupport sx={{mr: "10px"}} />
                Request Support
              </ButtonWithLink>
            </Typography>
          </Grid>
          <Grid item xs={6} align="left">
            <Typography variant="body2" sx={{padding: "14px"}}>
              <a href={`tel:${supportPhoneNumber}`} aria-label="Call">
                <IconButton color="secondary">
                  <Phone />
                </IconButton>
                {userFriendlyPhoneNumber}
              </a>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ContactCard;
